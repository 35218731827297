<template>
  <div class="hot-goods">
    <div class="header-box">
      <el-select v-model="classifyVal" style="width: 110px">
        <el-option v-for="item in ['全部分类']" :key="item" :label="item" :value="item"> </el-option>
      </el-select>
      <div style="display: flex">
        <div style="box-sizing: border-box; width: 400px; height: 40px; border: 1px solid #df0200"></div>
        <div style="width: 52px; height: 40px; background: #df0200; color: #fff; line-height: 40px; text-align: center">搜全站</div>
        <div style="width: 52px; height: 40px; background: #474e5c; color: #fff; line-height: 40px; text-align: center">搜本站</div>
      </div>
    </div>
    <div class="classify-box">
      <span v-for="(item, index) in detailsInfo.category_info">{{ item }} <span v-if="index !== detailsInfo.category_info.length - 1" style="margin: 4px">></span> </span>
    </div>
    <div class="goods-box">
      <div class="goods-img">
        <video :src="currentImg" controls style="width: 350px; height: 350px; margin-bottom: 10px; background: #000" v-if="currentType === 'video'"></video>
        <img :src="currentImg" style="width: 350px; height: 350px; margin-bottom: 10px" v-else />
        <div style="display: flex; flex-wrap: wrap">
          <template v-for="(item, index) in detailsInfo.goods_image">
            <img @click="changeImg(item, index)" :src="item" style="width: 40px; height: 40px; margin-right: 10px" />
          </template>
        </div>
      </div>
      <div>
        <div style="font-size: 24px; color: #333" class="two-line-text">{{ detailsInfo.goods_name }}</div>
        <div style="margin: 15px 0 30px 0; padding-left: 16px; height: 60px; line-height: 60px; background: #f7f7f7; color: #666">
          <span>价格</span>
          <span style="margin: 0 50px 0 10px; text-decoration-line: line-through">{{ "￥" + detailsInfo.goods_price }}</span>
          <span>折扣价</span>
          <span style="font-size: 24px; color: #ff0000">{{ "￥" + detailsInfo.goods_zhekou_price }}</span>
        </div>
        <div style="display: flex; align-items: center; color: #999">
          <div>配送至</div>
          <el-select v-model="areaVal" style="width: 160px; margin: 0 10px 0 20px" size="small">
            <el-option v-for="item in ['所有地区']" :key="item" :label="item" :value="item"> </el-option>
          </el-select>
          <div style="margin-right: 16px">有货</div>
          <div>免运费</div>
        </div>
        <div style="display: flex; align-items: center; color: #999; margin: 24px 0 50px 0">
          <div>颜色分类</div>
          <div style="padding: 0 10px; height: 44px; border: 1px solid #ccc; display: flex; align-items: center; margin-left: 7px">
            <img :src="detailsInfo.goods_cover ? detailsInfo.goods_image[1] : detailsInfo.goods_image[0]" style="width: 40px; height: 40px; margin-right: 10px" />
            <span>颜色</span>
          </div>
        </div>
        <div style="display: flex">
          <el-input-number v-model="goodsNum" :min="1" :max="100" style="width: 150px; margin-right: 50px"></el-input-number>
          <div style="width: 166px; height: 40px; background: #df3033; line-height: 40px; font-size: 20px; text-align: center; color: #fff">加入购物车</div>
        </div>
      </div>
    </div>
    <div class="title-box">
      <div class="title">宝贝详情</div>
    </div>
    <div v-html="detailsInfo.goods_content" style="margin: 30px 18.75%"></div>
  </div>
</template>

<script>
import { stuDecorateStuGoodsDet } from "@/utils/newDecorate";

export default {
  name: "HotGoods",
  components: {},
  props: {},
  data() {
    return {
      classifyVal: "全部分类",
      areaVal: "",
      goodsNum: 1,
      detailsInfo: {},
      currentImg: "",
      currentType: "",
    };
  },
  computed: {},
  methods: {
    getDetails() {
      stuDecorateStuGoodsDet({ id: this.$route.query.id }).then((res) => {
        res.data.goods_image = res.data.goods_image.map((item) => item.image_url);
        if (res.data.goods_cover) {
          res.data.goods_image.unshift(res.data.goods_cover);
        }
        this.detailsInfo = res.data;
        if (this.detailsInfo.goods_video) {
          this.currentType = "video";
          this.currentImg = this.detailsInfo.goods_video;
        } else {
          this.currentType = "img";
          this.currentImg = this.detailsInfo.goods_image[0];
        }
      });
    },
    changeImg(url, index) {
      console.log(url, index);
      if (this.detailsInfo.goods_video && !index) {
        this.currentType = "video";
        this.currentImg = this.detailsInfo.goods_video;
      } else {
        this.currentType = "img";
        this.currentImg = url;
      }
    },
  },
  mounted() {
    this.getDetails();
  },
};
</script>

<style scoped lang="scss">
.hot-goods {
  box-sizing: border-box;
  height: 100%;
  overflow-y: auto;
  .header-box {
    padding: 0 18.75%;
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .classify-box {
    height: 46px;
    line-height: 46px;
    background: #f2f2f2;
    color: #666;
    padding: 0 18.75%;
  }
  .goods-box {
    padding: 0 18.75%;
    display: flex;
    margin-top: 17px;
    .goods-img {
      width: 350px;
      margin-right: 17px;
    }
  }
  .title-box {
    margin: 40px 18.75% 10px 18.75%;
    height: 39px;
    border-bottom: 1px solid #e4393c;
    position: relative;
    background: #f7f7f7;
    .title {
      width: 106px;
      line-height: 39px;
      background: #e4393c;
      color: #fff;
      text-align: center;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .two-line-text {
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
}
</style>
