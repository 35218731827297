import http from "./request";

//获取考试完整信息
export const competitionstudent = (params) => {
  return http.get("/competition/competition", params);
};
export const removeGoodsVideo = (params) => {
  return http.post("decorate/removevideo", params);
};
export const decorateCategoryList = (params) => {
  return http.get("/operationProblem/categoryList", params);
};
export const stuDecorateStoreClassList = (params) => {
  return http.get("stuOp/storeClassList", params);
};
export const stuDecorateDelStoreClass = (params) => {
  return http.get("stuOp/delStoreClass", { params });
};
export const stuDecorateStoreClassSave = (params) => {
  return http.post("stuOp/storeClassSave", params);
};
export const stuDecorateSaveDzAndPoster = (params) => {
  return http.post("stuOp/saveDzAndPposter", params);
};
export const stuDecorateDzPosterList = (params) => {
  return http.get("stuOp/dzPosterList", { params });
};
export const stuDecorateFileDownload = (params) => {
  return http.get("stuOp/fileDownload", params);
};
export const stuDecorateSaveGoodsSort = (params) => {
  return http.post("stuOp/saveGoodsSort", params);
};
export const stuDecorateGoodsSortList = (params) => {
  return http.get("stuOp/goodsSortList", params);
};
export const stuDecorateUploadGoodsDetImg = (params) => {
  return http.post("stuOp/uploadGoodsDetImg", params);
};
export const stuDecorateStuSaveGoods = (params) => {
  return http.post("stuOp/stuSaveGoods", params);
};
export const stuDecorateStuGoodsList = (params) => {
  return http.get("stuOp/stuGoodsList", params);
};
export const stuDecorateGoodsAdd = (params) => {
  return http.post("stuOp/goodsAdd", params);
};
export const stuDecorateStuGoodsDet = (params) => {
  return http.get("stuOp/stuEditGoods", { params });
};
export const stuDecorateDelStuGoods = (params) => {
  return http.get("stuOp/delStuGoods", { params });
};
export const stuDecorateStuStoreInfo = (params) => {
  return http.get("stuOp/stuStoreInfo", params);
};
export const judgeDecorateStuAnswerList = (params) => {
  return http.get("judgeDecorate/stuAnswerList", params);
};
export const judgeDecorateJudgeScore = (params) => {
  return http.post("judgeDecorate/judgeScore", params);
};
export const judgeDecorateStuAnswerInfo = (params) => {
  return http.get("judgeDecorate/stuAnswerInfo", params);
};
export const saDecorateStuAnswer = (params) => {
  return http.get("saDecorate/stuAnswer", params);
};
export const stuDecorateStuTrainStoreInfo = (params) => {
  return http.get("stuOp/stuTrainStoreInfo", params);
};
export const stuDecorateTrainStuAnswer = (params) => {
  return http.get("stuOp/trainStuAnswer", params);
};
export const stuDecorateStuTrainStoreDet = (params) => {
  return http.get("stuOp/stuTrainStoreDet", params);
};
export const designModule = (params) => {
  return http.get("stuOp/dzPosterModule", params);
};
export const examInfo = (params) => {
  return http.get("stuOp/getExamInfo", { params });
};
export const examFullViewDetails = (params) => {
  return http.get("stuOp/stuStoreInfo", { params });
};
export const decorateStudentList = (params) => {
  return http.get("teacherop/dzPosterStuList", { params });
};
export const decorateStudentDetails = (params) => {
  return http.get("teacherop/dzPosterStuInfo", { params });
};
export const decorateJudgeScore = (params) => {
  return http.post("teacherop/dzPosterEvaluate", params);
};
